<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <h4>SPTRD</h4>
                <h4>SURAT PEMBERITAHUAN RETRIBUSI DAERAH</h4>
            </div>
            <div class="col-sm-6">
                <h4 class="float-right">
                    <i class="fa fa-info-circle"></i> Step 3 - RINCIAN RETRIBUSI
                </h4>
            </div>
        </div>
        <hr />
        <fieldset>
            <div class="row">
                <div class="col-sm-6">
                    <legend class="mb-1">Identitas Wajib Retribusi</legend>
                </div>
            </div>
            <div class="form-group row mb-1 mt-3">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Sumber Retribusi</label
                >
                <div class="col-md-3">
                    <input
                        type="email"
                        class="form-control"
                        v-model="sumber"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >NPWRD
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Induk"
                        v-model="npwrdDetail.no_pokok"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >NIB
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Induk"
                        v-model="npwrdDetail.no_induk"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Nama
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Password"
                        v-model="npwrdDetail.nama"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Nomor Handphone
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Handphone"
                        v-model="npwrdDetail.nomp"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Nomor Telepon
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Telepon"
                        v-model="npwrdDetail.notelepon"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Email
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        v-model="npwrdDetail.email"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Alamat</label
                >
                <div class="col-md-5">
                    <b-form-textarea
                        id="textarea"
                        v-model="npwrdDetail.alamat"
                        placeholder="Enter something..."
                        rows="6"
                        disabled
                    ></b-form-textarea>
                </div>
            </div>
            <hr />
        </fieldset>
        <form>
            <div class="row">
                <div class="col-sm-6">
                    <legend class="mb-1">
                        Masa Retribusi
                    </legend>
                </div>
            </div>
            <div class="form-group row mb-1 mt-3">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >No./Tgl. Pendataan</label
                >
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Pendataan"
                        v-model="spt.no_spt"
                        readonly
                    />
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <date-picker
                            id="dateTimeInput"
                            :config="options"
                            v-model="spt.created_at"
                            placeholder="-Pilih Tanggal-"
                            disabled
                        >
                        </date-picker>
                        <div class="input-group-append">
                            <label for="dateTimeInput" class="input-group-text">
                                <i class="fa fa-calendar"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row mb-4">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Masa</label
                >
                <div class="col-md-3">
                    <v-select
                        label="nama"
                        :reduce="nama => nama.id"
                        :options="bulan"
                        placeholder="Pilih"
                        v-model="spt.masa"
                        disabled
                    ></v-select>
                </div>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="2021"
                        v-model="spt.tahun"
                        disabled
                    />
                </div>
            </div>

            <!-- Modal Data --->
            <b-modal
                :title="
                    editMode
                        ? 'EDIT RINCIAN RETRIBUSI'
                        : 'TAMBAH RINCIAN RETRIBUSI'
                "
                v-model="modal.showModalForm"
                id="modal"
                hide-footer
                no-close-on-backdrop
                size="lg"
            >
                <form
                    @submit.prevent="
                        editMode ? update(sptDetailMixin.id) : store()
                    "
                    @keydown="form.onKeydown($event)"
                    autocomplete="off"
                >
                    <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label font-weight-bold"
                            >Jenis Jasa / Pelayanan
                            <span class="text-danger">*</span></label
                        >
                        <div class="col-md-9">
                            <v-select
                                label="nama"
                                :reduce="nama => nama.id"
                                :options="StepRetribusi.retjenis1Options"
                                v-model="form.ret_jenis_1_id"
                                placeholder="Pilih Jenis Jasa / Pelayanan"
                                @input="inputRetJenis1(form.ret_jenis_1_id)"
                            ></v-select>
                            <em
                                v-if="form.errors.has('ret_tarif_id')"
                                class="form-text text-danger"
                                >Isian Jenis Jasa / Pelayanan wajib diisi.</em
                            >
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label font-weight-bold"
                            >Rincian <span class="text-danger">*</span></label
                        >
                        <div class="col-md-9">
                            <v-select
                                label="nama"
                                :reduce="nama => nama.id"
                                :options="StepRetribusi.retjenis2Options"
                                v-model="form.ret_jenis_2_id"
                                placeholder="Pilih Rincian"
                                @input="inputRetJenis2(form.ret_jenis_2_id)"
                            >
                                <template v-slot:option="option">
                                    <span v-html="option.nama"></span>
                                </template>
                                <template #selected-option="{ nama }">
                                    <span v-html="nama"></span>
                                </template>
                            </v-select>
                            <em
                                v-if="form.errors.has('ret_tarif_id')"
                                class="form-text text-danger"
                                >Isian Rincian wajib diisi.</em
                            >
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label font-weight-bold"
                            >Sub Rincian
                            <span class="text-danger">*</span></label
                        >
                        <div class="col-md-9">
                            <v-select
                                label="nama"
                                :reduce="nama => nama.id"
                                :options="StepRetribusi.retjenis3Options"
                                v-model="form.ret_jenis_3_id"
                                placeholder="Pilih Sub Rincian"
                                @input="inputRetJenis3(form.ret_jenis_3_id)"
                            ></v-select>
                            <em
                                v-if="form.errors.has('ret_tarif_id')"
                                class="form-text text-danger"
                                >Isian Sub Rincian wajib diisi.</em
                            >
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label font-weight-bold"
                            >Parameter <span class="text-danger">*</span></label
                        >
                        <div class="col-md-9">
                            <v-select
                                label="ret_parameter"
                                :reduce="kode_dh => kode_dh.id"
                                :options="StepRetribusi.rettarifOptions"
                                placeholder="Pilih Parameter"
                                v-model="form.ret_tarif_id"
                                @input="inputRetTarif(form.ret_tarif_id)"
                            >
                                <template v-slot:option="option">
                                    <span v-html="option.ret_parameter"></span>
                                </template>
                                <template #selected-option="{ ret_parameter }">
                                    <span v-html="ret_parameter"></span>
                                </template>
                            </v-select>
                            <em
                                v-if="form.errors.has('ret_tarif_id')"
                                class="form-text text-danger"
                                >{{ errors.ret_tarif_id[0] }}</em
                            >
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label font-weight-bold">
                            Tarif Retribusi (Rp.) <span v-if="form.tarif_manual" class="text-danger">*</span>
                            <!-- &plusmn; -->
                        </label>
                        <div class="col-md-9">
                            <b-input-group class="col-md-5 p-0">
                                <b-input-group-prepend is-text>
                                    <b-form-checkbox 
                                        class="mr-n2"
                                        v-model="form.tarif_manual"
                                        @change="checkTarifManual()"
                                    >
                                        <span class="sr-only">Centang untuk memasukkan tarif manual</span>
                                    </b-form-checkbox>
                                    <!-- <b-form-checkbox switch class="mr-n2">
                                        <span class="sr-only">Centang untuk memasukkan tarif manual</span>
                                    </b-form-checkbox> -->
                                </b-input-group-prepend>
                                <!-- <b-input-group-prepend is-text><b>Rp.</b></b-input-group-prepend> -->
                                <b-form-input 
                                    class="form-control"
                                    v-model="form.tarif"
                                    :disabled="form.tarif_manual ? false : true"
                                    onfocus="this.select();"
                                    @keypress="isNumberKey($event)"
                                    @input="getTotal()"
                                    @blur="formatID_tarif($event)"
                                    aria-label="">
                                </b-form-input>
                            </b-input-group>
                            <em
                                v-if="form.errors.has('tarif_manual')"
                                class="form-text text-danger mt-0">
                                {{ errors.tarif_manual[0] }}
                            </em>
                            <em
                                v-if="form.errors.has('tarif')"
                                class="form-text text-danger mt-0">
                                {{ errors.tarif[0] }}
                            </em>
                            <div class="col-md-12 p-0">
                                <p class="m-0 pt-1 text-muted" style="line-height: 1.2em">
                                    <i>Centang untuk memasukkan tarif manual</i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label font-weight-bold"
                            >Tarif Retribusi (Rp.)
                        </label>
                        <div class="col-md-9">
                            <input
                                type="text"
                                class="form-control col-md-5"
                                v-model="form.tarif"
                                disabled
                            />
                        </div>
                    </div> -->
                    <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label">
                            <dl class="mb-0">
                                <dt class="text-inverse">Keterangan <span v-if="form.tarif_manual" class="text-danger">*</span></dt>
                                <!-- <dd class="m-0 text-muted" style="line-height: 1.2em">
                                    Wajib diisi jika memilih untuk menginput "Tarif Retribusi" 
                                    diluar dari PERDA dan PERGUB</dd> -->
                            </dl>
                        </label>
                        <div class="col-md-9">
                            <b-form-textarea
                                class="mb-0"
                                id="textarea-no-resize"
                                placeholder="Masukkan Keterangan"
                                rows="4"
                                no-resize
                                v-model="form.keterangan"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': form.errors.has(
                                        'keterangan'
                                    ),
                                }"
                            ></b-form-textarea>
                            <em
                                v-if="form.errors.has('keterangan')"
                                class="form-text text-danger mt-0">
                                {{ errors.keterangan[0] }}
                            </em>
                            <p class="m-0 pt-1 text-muted" style="line-height: 1.2em">
                                <strong class="font-weight-bold text-danger">Perhatian</strong><br>
                                <i>Kolom keterangan wajib diisi jika memilih untuk menginput <br>
                                "Tarif Retribusi" diluar dari PERDA dan PERGUB</i>
                            </p>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label font-weight-bold"
                            >Jumlah / Kuantitas
                            <span class="text-danger">*</span></label
                        >
                        <div class="col-md-9">
                            <b-form-input 
                                class="form-control col-md-4"
                                v-model="form.qty"
                                onfocus="this.select();"
                                @input="getTotal()"
                                @blur="formatID_qty($event)"
                                placeholder="Kuantitas"
                                aria-label="">
                            </b-form-input>
                            <em
                                v-if="form.errors.has('qty')"
                                class="form-text text-danger mt-0"
                                >{{ errors.qty[0] }}</em
                            >
                            <div class="col-md-12 p-0">
                                <p class="m-0 pt-1 text-muted" style="line-height: 1.2em">
                                    <strong class="font-weight-bold text-danger">Perhatian</strong><br>
                                    <i><ol class="px-1 mx-2 mb-0">
                                        <li>
                                            Masukkan jumlah/kuantitas dengan bilangan bulat
                                        </li>
                                        <li>
                                            atau masukkan dengan bilangan desimal dengan menggunakan koma ( , )<br>
                                            dan maksimal 2 digit di belakan koma ( , )
                                        </li>
                                    </ol></i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <label class="col-md-3 col-form-label font-weight-bold"
                            >Biaya Retribusi (Rp.)
                        </label>
                        <div class="col-md-9">
                            <div class="col-md-5 p-0">
                                <b-input-group>
                                    <b-input-group-prepend is-text><b>Rp.</b></b-input-group-prepend>
                                    <b-form-input 
                                        class="form-control text-right"
                                        v-model="form.nilai_terutang"
                                        disabled
                                        aria-label="">
                                    </b-form-input>
                                </b-input-group>
                            </div>
                            <!-- <input
                                type="text"
                                class="form-control"
                                v-model="form.nilai_terutang"
                                disabled
                            /> -->
                        </div>
                    </div>
                    <hr />
                    <div>
                        <span class="float-right">
                            <b-button variant="primary" type="submit">
                                <i class="fa fa-save"></i> Simpan
                            </b-button>
                        </span>
                    </div>
                </form>
            </b-modal>
            <!-- End of Modal -->

            <!-- Table -->
            <!-- Data Table & Pagination -->
            <b-table
                class="mb-0"
                show-empty
                small
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive
                bordered
            >
                <!-- PENGATURAN KOLOM 
                -------------------------------------------------- -->
                <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-2" small></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- ... -->
                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width: field.key === 'tarif' ? '75px' :
                                field.key === 'nilai_terutang' ? '100px' :
                                field.key === 'actions' ? '105px' : ''
                        }"
                    />
                </template>
                <template #head()="data">
                    <div class="" v-html="data.label"></div>
                </template>
                <!-- ... -->
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!-- PENGATURAN KOLOM 
                -------------------------------------------------- -->

                <!-- <template #foot()="data">
                    <span class="text-danger">{{ data.label }}</span>
                </template> -->
                <template #cell(ret_rincian)="data">
                    <div v-html="data.item.ret_rincian"></div>
                </template>
                <template #cell(tarif)="data">
                    {{
                        data.item.tarif
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }}
                </template>
                <template #cell(qty)="data">
                    {{
                        data.item.qty
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }}
                </template>
                <template #cell(nilai_terutang)="data">
                    {{
                        data.item.nilai_terutang
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }}
                </template>
                <template #custom-foot>
                    <b-tr>
                        <b-td colspan="4" class="bg-black-transparent-3">
                            <span class="float-right font-weight-bold">
                                Total
                            </span>
                        </b-td>
                        <b-td class="font-weight-bold text-right bg-black-transparent-3">
                            {{
                                sum_table.jumlah
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }}
                        </b-td>
                        <b-td class="font-weight-bold text-right bg-black-transparent-3">
                            {{
                                sum_table.total_retribusi
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }}
                        </b-td>
                        <b-td class="font-weight-bold text-right bg-black-transparent-3"></b-td>
                        <b-td class="font-weight-bold text-right bg-black-transparent-3"></b-td>
                    </b-tr>
                </template>

                <template v-slot:cell(actions)="data">
                    <b-dropdown
                        split
                        class="d-flex align-item-center"
                        size="sm"
                        variant="info"
                    >
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item @click="edit(data.item)"
                            ><i class="fa fa-edit"></i> Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="swalNotification('error', data.item.id)"
                            ><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item
                        >
                    </b-dropdown>
                </template>
            </b-table>
            <b-row class="py-2">
                <b-col>
                    <h3 class="float-right">
                        Total Biaya Retribusi
                        <i class="fa fa-arrow-circle-right"></i>: Rp.
                        {{
                            sum_table.total_retribusi
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                    </h3>
                </b-col>
            </b-row>
            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group :label-cols="2" label="Per page" class="mb-0">
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->
            <!-- end of Table -->
            <!-- end of table -->
            <hr />
            <span>
                <span>
                    <b-button variant="success" @click="reload">
                        <i class="fa fa-redo-alt"></i> Refresh
                    </b-button>
                </span>
                <span class="px-2">
                    <b-button variant="danger" @click="create">
                        <i class="fa fa-plus"></i> Tambah
                    </b-button>
                </span>
            </span>
            <span class="float-right">
                <span class="px-2">
                    <b-button variant="danger" @click="prevButton">
                        <i class="fa fa-arrow-left"></i> Sebelumnya
                    </b-button>
                </span>
                <!-- <span>
                    <b-button variant="success" @click="selesai">
                        Selesai & Cetak <i class="fa fa-arrow-right"></i>
                    </b-button>
                </span> -->
                <span>
                    <b-button variant="success" @click="formSelesaiShow">
                        Selesai <i class="fa fa-arrow-right"></i>
                    </b-button>
                </span>
            </span>
        </form>
        <!-- Selesai button loader -->
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>


        <b-modal
            :title="'PERIKSA TRANSAKSI DAN BERIKAN CATATAN'"
            v-model="modalFormSelesai.show"
            id="modalFormSelesai"
            no-close-on-backdrop
            centered
            size="lg"
        >
            <div class="form-group row mb-1">
                <div class="col-md-12">
                    <b-form-textarea
                        class="mb-0"
                        id="textarea-no-resize"
                        placeholder="Masukkan catatan"
                        rows="5"
                        no-resize
                        v-model="spt.keterangan"
                        :class="{
                            'form-control': true,
                            'is-invalid': form.errors.has(
                                'keterangan'
                            ),
                        }"
                    ></b-form-textarea>
                    <p class="m-0 pt-1 text-muted" style="line-height: 1.2em">
                        <strong class="font-weight-bold text-danger">Perhatian</strong><br>
                        <i><ol class="px-1 mx-2 mb-0">
                            <li>
                                Pastikan data transaksi retribusi telah benar
                            </li>
                            <li>
                                Masukkan catatan transaksi retribusi bila diperlukan
                            </li>
                            <li>
                                Catatan yang dimasukkan akan ditampilkan pada SKRD dan STS dari E-Keuangan
                            </li>
                        </ol></i>
                    </p>
                </div>
            </div>
            <template #modal-footer="{}">
                <div class="float-right">
                    <b-button class="mr-2" variant="light" type="submit" @click="formSelesaiHide">
                        <i class="fa fa-undo mr-1"></i> Kembali
                    </b-button>
                    <b-button variant="primary" type="submit" @click="selesaiKirim">
                        <i class="fa fa-paper-plane mr-1"></i> Kirim &amp; Cetak
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import axios from "axios";
import { mapState } from "vuex";
import sptDetailMixin from "@/helper/api/sptDetail.js";
import dateMixin from "@/helper/dateFormat.js";
import hapusStorage from "@/helper/hapusLocalStore";

export default {
    mixins: [sptDetailMixin, dateMixin, hapusStorage],
    data() {
        return {
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,

            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: "text-right"
                },
                {
                    key: "ret_rincian",
                    label: "Rincian Retribusi",
                    sortable: true
                },
                {
                    key: "ret_parameter",
                    label: "Parameter",
                    sortable: true
                },
                {
                    key: "tarif",
                    label: "Tarif (Rp./%)",
                    tdClass: "text-right"
                },
                {
                    key: "qty",
                    label: "Jumlah",
                    tdClass: "text-right"
                },
                {
                    key: "nilai_terutang",
                    label: "Biaya Retribusi (Rp.)",
                    tdClass: "text-right"
                },
                {
                    key: "keterangan",
                    label: "Keterangan"
                },
                {
                    key: "actions",
                    label: ""
                }
            ],
            npwr1: "",
            npwr2: "",
            npwr3: "",
            npwpd_arr: [],
            spt: new Form({
                masa: "",
                tahun: "",
                no_spt: "",
                keterangan: "",
                created_at: ""
            }),
            retribusiOptions: [],
            kode: "",
            bulan: [
                {
                    id: "01",
                    nama: "Januari"
                },
                {
                    id: "02",
                    nama: "Februari"
                },
                {
                    id: "03",
                    nama: "Maret"
                },
                {
                    id: "04",
                    nama: "April"
                },
                {
                    id: "05",
                    nama: "Mei"
                },
                {
                    id: "06",
                    nama: "Juni"
                },
                {
                    id: "07",
                    nama: "Juli"
                },
                {
                    id: "08",
                    nama: "Agustus"
                },
                {
                    id: "09",
                    nama: "September"
                },
                {
                    id: "10",
                    nama: "Oktober"
                },
                {
                    id: "11",
                    nama: "November"
                },
                {
                    id: "12",
                    nama: "Desember"
                }
            ],
            options: {
                format: "YYYY-MM-D",
                useCurrent: false
            },
            modal: {
                showModalForm: false
            },
            sptId: "",
            npwrdId: "",
            npwrdDetail: new Form({
                no_induk: "",
                alamat: "",
                nama: "",
                nomp: "",
                notelepon: "",
                email: "",
                no_pokok: ""
            }),
            sumber: "",
            form: new Form({
                ret_jenis_1_id: "",
                ret_jenis_2_id: "",
                ret_jenis_3_id: "",
                // ,,,
                spt_id: "",
                ret_tarif_id: "",
                tarif_manual: 0,
                tarif: "",
                qty: "",
                nilai: "",
                nilai_terutang: "",
                keterangan: ""
            }),
            modalFormSelesai: {
                show: false
            },
            user: JSON.parse(localStorage.getItem("user")),
            errors: [],
            sum_table: {
                total_retribusi: "0",
                jumlah: "0"
            },
            editMode: false,
            total_item: "",
            showLoader: false
        };
    },
    computed: {
        ...mapState(["StepRetribusi"])
    },
    mounted() {
        if (this.$route.name === "NextStep3") {
            this.getSpt(this.$route.params.id);
            this.reload();
            this.getUser(this.user.id);
        } else {
            this.npwrId = JSON.parse(localStorage.getItem("npwrdId"));
            this.getNpwrd(this.npwrId);
            this.sptId = JSON.parse(localStorage.getItem("sptId"));
            this.getSpt(this.sptId);
            this.getUser(this.user.id);
        }
    },
    methods: {
        // data table
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/transaksi/spt-detail", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    spt_id: this.sptId
                }
            });
            return promise
                .then(response => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    this.sum_table.total_retribusi =
                        response.data.meta.sum.nilai_terutang;
                    this.sum_table.jumlah = response.data.meta.sum.qty;
                    this.total_item = response.data.meta.count;
                    return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        store() {
            this.form.spt_id= this.sptId;
            this.form.tarif = this.formatIDtoEN(this.form.tarif);
            this.form.qty   = this.formatIDtoEN(this.form.qty);
            // ...
            this.form
                .post("/api/transaksi/spt-detail")
                .then(response => {
                    this.reset();
                    this.$swal({
                        icon: "success",
                        title: "Berhasil Menambah Data.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonClass: "btn btn-primary",
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000
                    });
                    this.reload();
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    } else if (error.response.status == 403) {
                        this.$swal({
                            icon: "error",
                            title: "TERJADI MASALAH",
                            text: error.response.data.status.message,
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                            timer: 5000
                        });
                    }
                });
            // ...
            this.form.tarif = this.formatENtoID(this.form.tarif);
            this.form.qty   = this.formatENtoID(this.form.qty);
        },

        // update data spt detail
        update(id) {
            this.form.tarif = this.formatIDtoEN(this.form.tarif);
            this.form.qty   = this.formatIDtoEN(this.form.qty);
            // ...
            this.form
                .put("/api/transaksi/spt-detail/" + id)
                .then(response => {
                    this.$swal({
                        icon: "success",
                        title: "Data Berhasil Dirubah.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonClass: "btn btn-primary",
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000
                    });
                    this.form.qty = this.form.qty.toString().replace(/\./g, ',')
                    this.reload();
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
            // ...
            this.form.tarif = this.formatENtoID(this.form.tarif);
            this.form.qty   = this.formatENtoID(this.form.qty);
        },
        // data npwrd
        getNpwrd(id) {
            axios
                .get("/api/wajib-retribusi/npwrd/" + id)
                .then(response => {
                    const items = response.data.data;
                    this.npwrdDetail.fill(items);
                    this.npwrdId = id;
                    this.npwrdDetail.alamat =
                        items.alamat +
                        "\nKel. " +
                        items.wilayah_kelurahan.nama +
                        "\nKec. " +
                        items.wilayah_kelurahan.wilayah_kecamatan.nama +
                        "\n" +
                        items.wilayah_kelurahan.wilayah_kecamatan
                            .wilayah_kabkota.nama +
                        "\n" +
                        items.wilayah_kelurahan.wilayah_kecamatan
                            .wilayah_kabkota.wilayah_provinsi.nama +
                        "\n" +
                        items.kodepos;
                    if (items.jenis < 41) {
                        this.sumber = "Badan";
                    } else {
                        this.sumber = "Perorangan";
                    }
                    const split = items.no_pokok.split("");
                    let first = [];
                    let second = [];
                    let third = [];
                    let npwpd_arr = [];
                    for (let item = 0; item < split.length; item++) {
                        if (item <= 1) {
                            first.push(split[item]);
                        } else if (item > 1 && item <= 7) {
                            second.push(split[item]);
                        } else {
                            third.push(split[item]);
                        }
                    }
                    npwpd_arr.push(first.join(""));
                    this.npwr1 = first.join("");
                    npwpd_arr.push(second.join(""));
                    this.npwr2 = second.join("");
                    npwpd_arr.push(third.join(""));
                    this.npwr3 = third.join("");
                    this.npwpd_arr = npwpd_arr.join(".");
                })
                .catch(error => {
                    consol.log(error);
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
        },

        // data spt
        getSpt(id) {
            axios
                .get("/api/transaksi/spt/" + id)
                .then(response => {
                    const items = response.data.data;
                    this.spt.fill(items);
                    if (this.$route.name === "NextStep3") {
                        this.getNpwrd(items.npwrd_id);
                    }
                    this.sptId = id;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
        },
        // data retribusi
        getUser(id) {
            axios
                .get("/api/manajemen/user/" + id)
                .then(response => {
                    const items = response.data.data;
                    this.$store.dispatch(
                        "StepRetribusi/dataRetJenis1",
                        items.pegawai.unit_pd.perangkat_pd_id
                    );
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
        },
        // show modal
        create() {
            this.editMode = false;
            this.reset();
            this.$root.$emit("bv::show::modal", "modal");
            this.modal.showModalForm == true;
        },

        // show modal update
        edit(item) {
            this.reset();
            // this.form.fill(item);
            // this.form.ret_jenis_1_id = item.ret_tarif.ret_jenis_3.ret_jenis_2.ret_jenis_1.id;
            // this.form.ret_jenis_2_id = item.ret_tarif.ret_jenis_3.ret_jenis_2.id;
            // this.form.ret_jenis_3_id = item.ret_tarif.ret_jenis_3.id;
            // ...
            this.editMode = true;
            this.$root.$emit("bv::show::modal", "modal");
            this.modal.showModalForm == true;
            this.dataSptDetail(item.id);
        },

        reset() {
            this.form.reset();
            this.form.clear();
            this.errors = [];
        },

        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },

        prevButton() {
            if (this.$route.name === "NextStep3") {
                this.$router.push({
                    name: "NextStep2",
                    params: { id: this.$route.params.id }
                });
            } else {
                this.$router.push({ name: "Step2" });
                localStorage.setItem("sptId", this.sptId);
                this.$store.commit("cekNpwrd/isPrev", true);
            }
        },

        // input retribusi jenis 1
        inputRetJenis1(id) {
            if (this.form.ret_jenis_1_id) {
                this.$store.dispatch("StepRetribusi/dataRetJenis2", id);
                this.form.ret_jenis_2_id = "";
                this.form.ret_jenis_3_id = "";
                this.form.ret_tarif_id = "";
                this.form.qty = "";
                this.form.nilai_terutang = "";
            } else {
                this.$store.commit("StepRetribusi/inputRetJenis2", []);
                this.$store.commit("StepRetribusi/inputRetJenis3", []);
                this.$store.commit("StepRetribusi/inputRetTarif", []);
                this.form.ret_jenis_2_id = "";
                this.form.ret_jenis_3_id = "";
                this.form.ret_tarif_id = "";
                this.form.qty = "";
                this.form.nilai_terutang = "";
            }
        },

        // input retribusi jenis 2
        inputRetJenis2(id) {
            if (this.form.ret_jenis_2_id) {
                this.$store.dispatch("StepRetribusi/dataRetJenis3", id);
                this.form.ret_jenis_3_id = "";
                this.form.ret_tarif_id = "";
                this.form.qty = "";
                this.form.nilai_terutang = "";
            } else {
                this.$store.commit("StepRetribusi/inputRetJenis3", []);
                this.$store.commit("StepRetribusi/inputRetTarif", []);
                this.form.ret_jenis_3_id = "";
                this.form.ret_tarif_id = "";
                this.form.qty = "";
                this.form.nilai_terutang = "";
            }
        },

        // input retribusi jenis 3
        inputRetJenis3(id) {
            if (this.form.ret_jenis_3_id) {
                this.$store.dispatch("StepRetribusi/dataRetTarif", id);
                this.form.ret_tarif_id = "";
                this.form.qty = "";
                this.form.nilai_terutang = "";
            } else {
                this.$store.commit("StepRetribusi/inputRetTarif", []);
                this.form.ret_tarif_id = "";
                this.form.qty = "";
                this.form.nilai_terutang = "";
            }
        },

        inputRetTarif(value) {
            if (value) {
                axios
                    .get("/api/retribusi/ret-tarif/" + value)
                    .then(response => {
                        const tarif = response.data.data.tarif;
                        this.form.tarif     = tarif.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        // ...
                        this.getTotal();
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            // hapus storage mixins
                            this.clearAll();
                        }
                    });
                // this.form.qty = "";
                // this.form.nilai_terutang = "";
            } else {
                this.form.qty = "";
                this.form.nilai_terutang = "";
            }
        },

        checkTarifManual(){
            this.inputRetTarif(this.form.ret_tarif_id);
        },
        
        /**
         * GET TOTAL & FORMAT CURRENCY
         * --------------------------------------------------
         */
        formatID_tarif(e){
            let tarif       = e.target.value ? e.target.value.toString().replaceAll('.','') : 0;
            this.form.tarif = tarif.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatID_qty(e){
            let qty         = e.target.value ? e.target.value.toString().replaceAll('.','') : 0;
            qty             = qty.toString().replace(',', '.'); // mengganti desimal , menjadi .
            qty             = parseFloat(qty).toFixed(2); // membatasi hanya 2 digit desimal
            qty             = qty.toString().replace('.', ','); // mengganti desimal . menjadi ,
            this.form.qty   = qty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },

        // delete data on table
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default"
            }).then(result => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/transaksi/spt-detail/" + id)
                        .then(response => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 5000
                            }).catch(error => {
                                if (error.response.status === 401) {
                                    // hapusToken Mixin
                                    this.clearAll();
                                }
                            });
                        });
                }
                this.reload();
            });
        },
        formSelesaiShow() {
            if (this.total_item !== 0) {
                this.$root.$emit("bv::show::modal", "modalFormSelesai");
                this.modalFormSelesai.show = true;
                // ...
                // this.showLoader = true;
                // axios
                //     .put("/api/transaksi/spt/" + this.sptId + "/selesai")
                //     .then(response => {
                //         this.showLoader = false;
                //         this.$swal({
                //             title: "Berhasil",
                //             text:
                //                 "Terima kasih telah menyelesaikan laporan pada masa retribusi ini.",
                //             icon: "success",
                //             showCloseButton: true,
                //             showConfirmButton: true,
                //             timer: 5000,
                //             confirmButtonText: "OKE"
                //         });
                //         this.$router.push({ name: "Step4" });
                //         localStorage.setItem("sptId", response.data.data.id);
                //     })
                //     .catch(error => {
                //         this.showLoader = false;
                //         if (error.response.status === 401) {
                //             // hapus storage mixins
                //             this.clearAll();
                //         }
                //     });
            } else {
                this.$swal({
                    icon: "error",
                    title: "Anda Belum Menambahkan Data Rincian Retribusi",
                    text: "Tambahkan terlebih dahulu rincian retribusi.",
                    confirmButtonClass: "btn btn-info",
                    showConfirmButton: true,
                    confirmButtonClass: "btn btn-primary",
                    confirmButtonText: "OKE",
                    showCloseButton: true,
                    timer: 5000
                });
            }
        },
        formSelesaiHide(){
            this.$root.$emit("bv::hide::modal", "modalFormSelesai");
            this.modalFormSelesai.show == false;
        },
        selesaiKirim(){
            this.showLoader = true;
            // axios
            //     .put("/api/transaksi/spt/" + this.sptId + "/selesai")
            this.spt
                .put("/api/transaksi/spt/" + this.sptId + "/selesai")
                .then(response => {
                    this.showLoader = false;
                    this.$swal({
                        title: "Berhasil",
                        text:
                            "Terima kasih telah menyelesaikan laporan pada masa retribusi ini.",
                        icon: "success",
                        showCloseButton: true,
                        showConfirmButton: true,
                        timer: 5000,
                        confirmButtonText: "OKE"
                    });
                    this.$router.push({ name: "Step4" });
                    localStorage.setItem("sptId", response.data.data.id);
                })
                .catch(error => {
                    this.showLoader = false;
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
        },

        /**
         * LIBRARY NUMBER, CURRENCY ...
         * --------------------------------------------------
         */
        isNumberKey(evt)
        {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        formatMoney(number, decPlaces, decSep, thouSep) {
            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
            decSep = typeof decSep === "undefined" ? "." : decSep;
            thouSep = typeof thouSep === "undefined" ? "," : thouSep;
            var sign = number < 0 ? "-" : "";
            var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
            var j = (j = i.length) > 3 ? j % 3 : 0;

            return sign +
                (j ? i.substr(0, j) + thouSep : "") +
                i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
                (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
        }
    }
};
</script>
